<template>
  <div class="container-fluid">
    <div class="row cookie-container">
      <div class="nav-cookies">
        <div class="cookies">
          <div class="container">
            <!-- eslint-disable vue/no-v-html -->
            <p
              class="cookie-consent-text"
              v-html="$t('cookie.cookie_consent')"
            />
            <a
              class="cookie-accept"
              href="#"
              role="button"
              @click="add_tracking"
            >
              <i
                id="inner-cookie-text"
                :class="brand_name == 'The International' ? 'fa btn btn-primary' : 'fa fa-check-circle'"
              >
                <span class="inherit-font">
                  {{ $t('cookie.continue') }}
                </span>
              </i>
            </a>
            <!--eslint-enable-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    brand_name() {
      return window.brand_name
    }
  },

  methods: {
    add_tracking: function(event) {
      event.preventDefault()
      document.cookie = `tracking=true;expires=${new Date(new Date().getTime()+365*60*60*1000*24).toGMTString()}`
      $('.cookies').slideUp()
      $('.navbar').animate({ top: '0' })
      $('body').removeClass('cookie-consent-dropdown')
    }
  }
}
</script>
