<template>
  <div
    v-if="mementoCodes.length > 0"
    class="opportunity-memento-code opportunity-details-block"
  >
    <div
      :class="[expandedClass, 'opportunity-details-header']"
      @click="expanded = !expanded"
    >
      <p class="opportunity-details-header-title">
        {{ $t('fanaticCodes.yourGiftCode', { eventName: eventName }) }}
      </p>
      <span class="opportunity-details-expand-icon" />
    </div>

    <div :class="[expandedClass, 'opportunity-details-content']">
      <p>
        {{ $t('mementoCodes.instructions', { eventName: programName }) }}
      </p>

      <div class="memento-code-group">
        <a
          v-for="(url, index) in codeValues"
          :key="url"
          :href="url"
          role="button"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>{{ $t('mementoCodes.giftNumber', { number: index + 1 }) }}</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import Clipboard from './click_to_copy'

export default {
  components: {
    // Clipboard
  },

  data() {
    return {
      expanded: false,
      eventName: null,
      programName: null,
      opportunityId: null,
      orderId: null,
      mementoCodes: []
    }
  },

  computed: {
    codeValues() {
      return this.mementoCodes.map(code => { return code.name })
    },

    expandedClass() {
      return (this.expanded == true) ? 'opened' : 'closed'
    }
  },

  created() {
    let elem = document.getElementById('opportunityMementoCode')
    if (elem) {
      this.opportunityId = elem.dataset.opportunityid
      this.orderId = elem.dataset.orderid
      this.eventName = elem.dataset.eventname
      this.programName = elem.dataset.programname || this.eventName
    }
    if(this.opportunityId && this.orderId){
      this.fetchMementoCodes()
    }
  },

  methods: {
    fetchMementoCodes() {
      return fetch(`/oa/opportunities/${this.opportunityId}/memento_codes?orderId=${this.orderId}`, {
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      }).then((resp) => {
        if (resp.status == 200) {
          return resp.json().then((body) => { this.mementoCodes = body.memento_codes })
        }
      })
    }
  }
}
</script>
