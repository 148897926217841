let updateClasses = (header, content) => {
  if (header.classList.contains('closed')) {
    header.classList.remove('closed')
    content.classList.remove('closed')

    header.classList.add('opened')
    content.classList.add('opened')
  } else {
    header.classList.remove('opened')
    content.classList.remove('opened')

    header.classList.add('closed')
    content.classList.add('closed')
  }
}

export default {
  addOpenCloseClasses() {
    Array.from(document.querySelectorAll('.opportunity-details-block.expand-block:not(.turbo-frame-expand)')).forEach(div => {
      let header = div.querySelector('.opportunity-details-header')
      let content = div.querySelector('.opportunity-details-content')

      if (header && content) {
        header.addEventListener('click', () => { updateClasses(header, content) })
      }
    })
  },

  addOpenCloseClassesToTurboFrames(){
    Array.from(document.querySelectorAll('.opportunity-details-block.expand-block.turbo-frame-expand')).forEach(div => {
      let header = div.querySelector('.opportunity-details-header')
      let content = div.querySelector('.opportunity-details-content')

      if (header && content) {
        header.addEventListener('click', () => { updateClasses(header, content) })
      }
    })
  },

  allowCopy() {
    document.querySelectorAll('.nba-code').forEach((element) => {
      element.addEventListener('click', async function () {
        const copyText = this.getAttribute('data-code')

        if(navigator.clipboard){
          navigator.clipboard.writeText(copyText)
          Array.from(this.children).forEach((child) => {
            if(child.classList.contains('clipboard-click-text')){
              child.innerText = `Copied ${copyText}`
            }
          })
        } else {
          const textArea = document.createElement('textarea')
          textArea.value = copyText
          document.body.appendChild(textArea)
          textArea.select()
          document.execCommand('copy')
          Array.from(this.children).forEach((child) => {
            if(child.classList.contains('clipboard-click-text')){
              child.innerText = `Copied ${copyText}`
            }
          })
          document.body.removeChild(textArea)
        }
      })
    })
  },
}
