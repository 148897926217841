<template>
  <div>
    <div
      v-if="isFormActive"
      class="form-group"
    >
      <Field
        :id="`info_request_${formType}_favorite_team`"
        v-model="localFavoriteTeam"
        as="select"
        class="form-control select"
        :class="`info_request_${formType}_favorite_team`"
        name="favorite_team"
        @change="emitFavoriteTeam"
      >
        <option value="">
          {{ optionLabel }}
        </option>
        <option
          v-for="(team, index) in favoriteTeams"
          :key="index"
          :value="team"
        >
          {{ team }}
        </option>
      </Field>
    </div>
  </div>
</template>

<script>
import { Field } from 'vee-validate'

export default {
  name: 'FavoriteTeamDropdown',
  components: {
    Field,
  },
  props: {
    formType: {
      type: String,
      required: true,
      default: '',
    },
    favoriteTeam: {
      type: String,
      required: false,
      default: '',
    },
    favoriteTeams: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      localFavoriteTeam: this.favoriteTeam || '',
    }
  },
  computed: {
    isFormActive() {
      let allowedForms = ['f1','nba', 'wnba', 'nhl']
      return allowedForms.includes(this.formType)
    },
    optionLabel() {
      return this.formType === 'f1' ? this.$t('form.favourite_team') : 'Favorite Team'
    },
  },
  watch: {
    favoriteTeam(newVal) {
      this.localFavoriteTeam = newVal
    },
  },
  methods: {
    emitFavoriteTeam() {
      this.$emit('favorite-team-emit', this.localFavoriteTeam)
    },
  },
}
</script>
