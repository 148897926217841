import VueLoader from '../../vue-loader'

import Expand from './expand.js'
import F1Tv from './f1_tv'
import FanaticCodes from './fanatic_codes'
import CancellationForm from './cancellation_form'
import LineItems from './line_items'
import MementoCodes from './memento_codes'
import PaymentTermsList from '../payment_terms/list_no_charge'
import PaymentTermsListChargeable from '../payment_terms/list_chargeable'
import RoomingLists from './rooming_lists'
import ShipmentCode from './shipment_code'
import { store } from'../../../default/store'

document.addEventListener('turbo:load', () => {
  Expand.addOpenCloseClasses()
  VueLoader.loadVueComponent(PaymentTermsListChargeable,
    document.getElementById('opportunityPaymentTermsToCharge'), store)
  VueLoader.loadVueComponent(PaymentTermsList,
    document.getElementById('opportunityPaymentTermsBasicInfo'), store)

  VueLoader.loadVueComponent(RoomingLists, document.getElementById('opportunityRoomingList'), store)

  VueLoader.loadVueComponent(CancellationForm, document.getElementById('opportunityCancellation'), store)
  VueLoader.loadVueComponent(LineItems, document.getElementById('opportunityLineItems'), store)

  VueLoader.loadVueComponent(F1Tv, document.getElementById('opportunityF1TvCode'), store)
  VueLoader.loadVueComponent(FanaticCodes, document.getElementById('opportunityFanaticCode'), store)
  VueLoader.loadVueComponent(ShipmentCode, document.getElementById('opportunityShipmentCode'), store)
  VueLoader.loadVueComponent(MementoCodes, document.getElementById('opportunityMementoCode'), store)
})


document.addEventListener('turbo:frame-load', () => {
  Expand.addOpenCloseClassesToTurboFrames()
  Expand.allowCopy()
})

export default {}
