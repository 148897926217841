<template>
  <div>
    <div
      v-if="confirmed"
      class="customer_service_form_response"
    >
      <p>
        {{ $t('customer_service_form.thank_you') }}
      </p>
      <p>
        {{ $t('customer_service_form.message') }}
      </p>
      <a
        class="btn close-btn"
        role="button"
        @click="closeConfirmation()"
      >{{ $t('messages.close') }}
      </a>
    </div>
    <form
      v-else
      id="new_customer_service_form"
      class="customer-service-form"
      @submit.prevent="submitForm"
    >
      <div
        class="field control"
        :class="{'has-error': errors.has('first_name') }"
      >
        <input
          id="customer_service_form_first_name"
          v-model="first_name"
          v-validate="'required'"
          class="input"
          :placeholder="$t('form.first_name')"
          type="text"
          name="first_name"
          :editable="false"
        >
        <label
          v-show="errors.has('first_name')"
          class="error is-danger help"
          for="customer_service_form_first_name"
        >{{ errors.first('first_name') }}</label>
      </div>
      <div
        class="field control"
        :class="{'has-error': errors.has('last_name') }"
      >
        <input
          id="customer_service_form_last_name"
          v-model="last_name"
          v-validate="'required'"
          class="input"
          :placeholder="$t('form.last_name')"
          type="text"
          name="last_name"
        >
        <label
          v-show="errors.has('last_name')"
          class="error is-danger help"
          for="customer_service_form_last_name"
        >{{ errors.first('last_name') }}</label>
      </div>
      <div
        class="field control"
        :class="{'has-error': errors.has('email') }"
      >
        <input
          id="customer_service_form_email"
          v-model="email"
          v-validate="'required|email'"
          class="input"
          :placeholder="$t('form.email')"
          type="email"
          name="email"
        >
        <label
          v-show="errors.has('email')"
          class="error is-danger help"
          for="customer_service_form_email"
        >{{ errors.first('email') }}</label>
      </div>
      <div id="hint" />
      <div
        class="field control"
        :class="{'has-error': errors.has('phone') }"
      >
        <input
          id="customer_service_form_phone"
          v-model="phone"
          v-validate="'required|min:10'"
          class="input"
          :placeholder="$t('form.phone')"
          type="text"
          name="phone"
        >
        <label
          v-show="errors.has('phone')"
          class="error is-danger help"
          for="customer_service_form_phone"
        >{{ errors.first('phone') }}</label>
      </div>
      <div
        class="field"
        :class="{'has-error': errors.has('reason_for_inquiry') } "
      >
        <span class="control is-expanded">
          <select
            id="customer_service_form_reason_for_inquiry"
            v-model="reason_for_inquiry"
            v-validate="'required'"
            class="customer_service_form_reason_for_inquiry select is-fullwidth"
            name="reason_for_inquiry"
          >
            <option
              value=""
              selected
            >
              Reason for Inquiry
            </option>
            <option
              v-for="(option, index) in inquiryOptions"
              :key="index"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </span>
        <label
          v-show="errors.has('reason_for_inquiry')"
          class="error is-danger help"
          for="customer_service_form_reason_for_inquiry"
        >{{ errors.first('reason_for_inquiry') }}</label>
      </div>
      <div class="field control">
        <textarea
          id="customer_service_form_comments"
          v-model="comments"
          class="textarea"
          :placeholder="$t('form.comments')"
          maxlength="2000"
          name="comments"
        />
        <label
          v-show="comments.length >= 2000"
          class="error is-danger help"
          for="customer_service_form_comments"
        >2000 character max</label>
      </div>
      <div class="field control">
        <input
          id="customerServiceFormSubmitButton"
          type="submit"
          name="commit"
          class="btn btn-primary is-fullwidth"
          data-ga-event-action="Submit"
          :disabled="disabled"
        >
        <div v-if="validationError">
          {{ validation_errors.join(', ') }}
        </div>
        <span
          v-else-if="processingError"
          class="help is-danger"
        >{{ $t('submitFormError') }}</span>
      </div>
    </form>
  </div>
</template>
<script>

export default {
  name: 'CustomerSupportForm',
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      formData: {},
      confirmation_number: 'generic',
      inquiryOptions: ['Modify Reservation', 'Cancel My Existing Reservation', 'Special Reservation Requests'],
      reason_for_inquiry:'',
      comments: '',
      disabled: false,
      processingError: false,
      confirmed: false,
      validationError: false,
      validation_errors: []
    }
  },

  methods: {
    setFormData() {
      return {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone: this.phone,
        confirmation_number: this.confirmation_number,
        comments: this.comments,
        reason_for_inquiry: this.reason_for_inquiry,
      }
    },

    postFormData: async function () {
      try {
        const response = await fetch('/customer_service_forms.json', {
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(this.formData),
        })
        response.json().then(data => {
          if(data) {
            this.validationError = true
            this.validation_errors = data
          } else {
            this.validationError = false
            this.validation_errors = []
          }
        })
        if (!response.ok) {
          throw 'Bad response'
        }
        this.disabled = false
        this.confirmed = true
      } catch {
        if(!this.validationError){
          this.processingError = true
        }
        this.disabled = false
      }
    },

    submitForm(e) {
      e.preventDefault()
      this.$validator.validate().then(valid => {
        if(valid) {
          this.disabled = true
          this.formData = this.setFormData()
          this.postFormData()
        } else {
          this.disabled = false
        }
      })
    },

    wipeData() {
      this.first_name = ''
      this.last_name = ''
      this.email = ''
      this.phone = ''
      this.formData =  {}
      this.comments = ''
      this.reason_for_inquiry = ''
      this.disabled = false
      this.processingError = false
      this.validationError = false
      this.validation_errors = []
    },

    closeConfirmation() {
      this.confirmed = false
      this.wipeData()
    },
  },
}
</script>