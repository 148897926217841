<template>
  <div>
    <div
      v-if="confirmed"
      class="customer_service_form_response"
    >
      <p>
        {{ $t('customer_service_form.thank_you') }}
      </p>
      <p>
        {{ $t('customer_service_form.message') }}
      </p>
      <a
        class="btn close-btn"
        role="button"
        @click="closeConfirmation()"
      >{{ $t('messages.close') }}
      </a>
    </div>
    <form
      v-else
      id="new_customer_service_form"
      class="customer-service-form"
      @submit.prevent="submitForm"
    >
      <div
        class="form-group"
        :class="{'has-error': errors.has('first_name') }"
      >
        <input
          id="customer_service_form_first_name"
          v-model="first_name"
          v-validate="'required'"
          class="form-control"
          :placeholder="$t('form.first_name')"
          type="text"
          name="first_name"
          :editable="false"
        >
        <label
          v-show="errors.has('first_name')"
          class="error"
          for="customer_service_form_first_name"
        >{{ errors.first('first_name') }}</label>
      </div>
      <div
        class="form-group"
        :class="{'has-error': errors.has('last_name') }"
      >
        <input
          id="customer_service_form_last_name"
          v-model="last_name"
          v-validate="'required'"
          class="form-control"
          :placeholder="$t('form.last_name')"
          type="text"
          name="last_name"
        >
        <label
          v-show="errors.has('last_name')"
          class="error"
          for="customer_service_form_last_name"
        >{{ errors.first('last_name') }}</label>
      </div>
      <div
        class="form-group"
        :class="{'has-error': errors.has('email') }"
      >
        <input
          id="customer_service_form_email"
          v-model="email"
          v-validate="'required|email'"
          class="form-control"
          :placeholder="$t('form.email')"
          type="email"
          name="email"
        >
        <label
          v-show="errors.has('email')"
          class="error"
          for="customer_service_form_email"
        >{{ errors.first('email') }}</label>
      </div>
      <div id="hint" />
      <div
        class="form-group"
        :class="{'has-error': errors.has('phone') }"
      >
        <input
          id="customer_service_form_phone"
          v-model="phone"
          v-validate="'required|min:10'"
          class="form-control"
          :placeholder="$t('form.phone')"
          type="text"
          name="phone"
        >
        <label
          v-show="errors.has('phone')"
          class="error"
          for="customer_service_form_phone"
        >{{ errors.first('phone') }}</label>
      </div>
      <div
        class="form-group"
        :class="{'has-error': errors.has('confirmation_number') }"
      >
        <input
          id="customer_service_form_confirmation_number"
          v-model="confirmation_number"
          v-validate="'required'"
          class="form-control"
          :placeholder="$t('form.confirmation_number')"
          type="text"
          name="confirmation_number"
        >
        <label
          v-show="errors.has('confirmation_number')"
          class="error"
          for="customer_service_form_confirmation_number"
        >{{ errors.first('confirmation_number') }}</label>
      </div>
      <div
        class="form-group"
        :class="{'has-error': errors.has('hotel_name') }"
      >
        <input
          id="customer_service_form_hotel_name"
          v-model="hotel_name"
          v-validate="'required'"
          class="form-control"
          :placeholder="$t('form.hotel_name')"
          type="text"
          name="hotel_name"
        >
        <label
          v-show="errors.has('hotel_name')"
          class="error"
          for="customer_service_form_hotel_name"
        >{{ errors.first('hotel_name') }}</label>
      </div>
      <div
        class="form-group checkin_date"
        :class="{'has-error': errors.has('checkin_date') }"
      >
        <date-picker
          v-model="checkin_date"
          :placeholder="$t('form.checkin_date')"
          name="checkin_date"
          :append-to-body="false"
        />
        <label
          v-show="errors.has('checkin_date')"
          class="error"
          for="customer_service_form_checkin_date"
        >{{ errors.first('checkin_date') }}</label>
      </div>
      <div
        class="form-group"
        :class="{'has-error': errors.has('reason_for_inquiry') }"
      >
        <select
          id="customer_service_form_reason_for_inquiry"
          v-model="reason_for_inquiry"
          v-validate="'required'"
          class="form-control select customer_service_form_reason_for_inquiry"
          name="reason_for_inquiry"
        >
          <option
            value=""
            selected
          >
            Reason for Inquiry
          </option>
          <option
            v-for="(option, index) in inquiryOptions"
            :key="index"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <label
          v-show="errors.has('reason_for_inquiry')"
          class="error"
          for="customer_service_form_reason_for_inquiry"
        >{{ errors.first('reason_for_inquiry') }}</label>
      </div>

      <div class="form-group">
        <textarea
          id="customer_service_form_comments"
          v-model="comments"
          class="form-control"
          :placeholder="$t('form.comments')"
          maxlength="2000"
          name="comments"
        />
        <label
          v-show="comments.length >= 2000"
          class="error"
          for="customer_service_form_comments"
        >2000 character max</label>
      </div>
      <div>
        <input
          id="customerServiceFormSubmitButton"
          type="submit"
          name="commit"
          class="btn btn-secondary"
          data-ga-event-action="Submit"
          :disabled="disabled"
        >
        <div v-if="validationError">
          {{ validation_errors.join(', ') }}
        </div>
        <span v-else-if="processingError">{{ $t('submitFormError') }}</span>
      </div>
    </form>
  </div>
</template>
<script>
import DatePicker from 'vue-datepicker-next'

export default {
  name: 'CustomerServiceForm',
  components: {
    DatePicker
  },

  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      formData: {},
      checkin_date: '',
      confirmation_number: '',
      hotel_name: '',
      comments: '',
      inquiryOptions: ['New Reservation', 'Cancellation of Existing Reservation', 'Reservation Modification', 'Date of Arrival Issue', 'Unable to Check-In', 'Other'],
      reason_for_inquiry:'',
      disabled: false,
      processingError: false,
      confirmed: false,
      validationError: false,
      validation_errors: []
    }
  },

  computed: {
  },

  methods: {
    setFormData() {
      return {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone: this.phone,
        checkin_date: this.checkin_date,
        confirmation_number: this.confirmation_number,
        hotel_name: this.hotel_name,
        comments: this.comments,
        reason_for_inquiry: this.reason_for_inquiry,
      }
    },

    postFormData: async function () {
      try {
        const response = await fetch('/customer_service_forms.json', {
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(this.formData),
        })
        response.json().then(data => {
          if(data) {
            this.validationError = true
            this.validation_errors = data
          } else {
            this.validationError = false
            this.validation_errors = []
          }
        })
        if (!response.ok) {
          throw 'Bad response'
        }
        this.disabled = false
        this.confirmed = true
      } catch {
        if(!this.validationError){
          this.processingError = true
        }
        this.disabled = false
      }
    },

    submitForm(e) {
      e.preventDefault()
      this.$validator.validate().then(valid => {
        if(valid) {
          this.disabled = true
          this.formData = this.setFormData()
          this.postFormData()
        } else {
          this.disabled = false
        }
      })
    },

    wipeData() {
      this.first_name = ''
      this.last_name = ''
      this.email = ''
      this.phone = ''
      this.formData =  {}
      this.checkin_date = ''
      this.confirmation_number = ''
      this.hotel_name = ''
      this.comments = ''
      this.reason_for_inquiry = ''
      this.disabled = false
      this.processingError = false
      this.validationError = false
      this.validation_errors = []
    },

    closeConfirmation() {
      this.confirmed = false
      this.wipeData()
    },
  },
}
</script>